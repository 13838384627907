//---------------------------\\
//--- Fonctions générales ---\\
//---------------------------\\

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Dans ce fichier on retrouve les fonctions générales qu'on ne peut pas vraiment catégoriser
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

// Importation ----------------------------------------------------------------------------------------------------
import $ from 'jquery'
import Scrollbar from 'smooth-scrollbar'
//-----------------------------------------------------------------------------------------------------------------


// Fonction initialisant les tiroirs
export function drawers() {
  $('.drawers > li .toggle').on('click', function() {
    var ouvert = $(this).parent().hasClass('open')
    $('.drawers li.open .drawer').animate({ height: 'hide' }, { duration: 1000, specialEasing: { height: 'easeOutExpo' }})
    $('.drawers li.open').removeClass('open')
    if (!ouvert) {
      var chosen = this
      setTimeout(function() { $(chosen).parent().addClass('open') }, 0)
      $('.drawer', $(this).parent()).stop(true, false).animate({ height: 'show' }, { duration: 1000, specialEasing: { height: 'easeOutExpo' }, complete: function() {						
        if(Scrollbar.has(document.querySelector('[data-mainScrollbar]'))) {
          window.mainScrollbar.scrollTo(0, $(chosen).offset().top + window.mainScrollbar.offset.y, 600)
        } else {
          $('html, body').animate({ scrollTop: $(chosen).offset().top }, 600, 'easeInOutExpo')
        }
      }})
    }
    return false
  })
}


// Fonction permettant de défiller d'un block à un autre à l'aide d'un clique
export function scrollToBlock(depart, destination, duration, offset) {
  // Paramètres optionnels
  duration = (typeof duration === 'undefined') ? 600 : duration
  offset   = (typeof offset   === 'undefined') ? 0   : offset

  // Défilement au clique
  $(depart).on( 'click', function() {
    if($(window).width() <= 1024) offset = 0

    if(Scrollbar.has(document.querySelector('[data-mainScrollbar]'))) {
      window.mainScrollbar.scrollTo(0, ( destination != '' ? ($(destination).offset().top + window.mainScrollbar.offset.y) + offset : 0), 600)
    } else {
      $('html, body').animate({ scrollTop: ( destination != '' ? $(destination).offset().top + offset : 0 ) }, duration, 'easeInOutExpo')
    }
  })
}

export function scrollTo(destination, duration, offset) {
  duration = (typeof duration === 'undefined') ? 600 : duration
  offset   = (typeof offset   === 'undefined') ? 0   : offset

  if($(window).width() <= 1024) offset = 0
  if(Scrollbar.has(document.querySelector('[data-mainScrollbar]'))) {
    window.mainScrollbar.scrollTo(0, ( destination != '' ? ($(destination).offset().top + window.mainScrollbar.offset.y) + offset : 0), 600)
  } else {
    $('html, body').animate({ scrollTop: ( destination != '' ? $(destination).offset().top + offset : 0 ) }, duration, 'easeInOutExpo')
  }
}

// Fonction gèrant l'alerte
export function alert() {
  $('.alertBlock .close').on('click', () => {
    $('html').removeClass('showOverlayAlert')
  })
}

export function propertiesLoaded(data) {
  if (!data['hasMorePages']) $('#propertiesMoreBtn').hide()
  if(data['currentPage'] != 1){
    var pageSelector = '.properties_page_' + data['currentPage']
    console.log(pageSelector)
    scrollTo(pageSelector,600,-60)
  }
}

export function getImageOnClick() {
  var active = ''
  $('.reasonsBlock .reasons .reason').on('click', function() {
    active = $(this).attr('data-reason')

    $('.reasonsBlock .reasons .reason').each(function() {
      $(this).removeClass('active')
    })

    $('.reasonsBlock .images a').each(function() {
      $(this).removeClass('active')
    })

    $('[data-reason="'+ active +'"], [data-reasonimage="'+ active +'"]').addClass('active')
  })

}


export function initSharethis() {

  if (typeof __sharethis__ !== 'undefined' && __sharethis__.config) {
    __sharethis__.init(__sharethis__.config)
  }
}

export function copyTextToClipboard(text) {
  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text)
    return
  }
  navigator.clipboard.writeText(text).then(function() {
    console.log('Async: Copying to clipboard was successful!')
  }, function(err) {
    console.error('Async: Could not copy text: ', err)
  })
}

function fallbackCopyTextToClipboard(text) {

  var textArea = document.createElement('textarea')
  textArea.value = text
  document.body.appendChild(textArea)
  textArea.focus()
  textArea.select()

  var successful = document.execCommand('copy')

  document.body.removeChild(textArea)
}

export function shareUrl(object) {
  var link = $(object).data('url')
  copyTextToClipboard(link)
  alert('Un url a été copié sur votre presse-papiers.')
  return false
}


export function fancyPhotos() {
  $('.fancybox').fancybox({
    animationEffect: 'slide',
    animationDuration: '600',
    transitionEffect: 'slide',
    transitionDuration: '600',
    loop: true,
    buttons: [
      'slideShow',
      'fullScreen',
      'thumbs',
      'close'
    ],
  })
}


