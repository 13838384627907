//---------------------------\\
//--- Fonctions de scroll ---\\
//---------------------------\\

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Dans ce fichier on retrouve les fonctions utilisant le smooth scroll
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

// Importation ----------------------------------------------------------------------------------------------------
import $ from 'jquery'
import Scrollbar from 'smooth-scrollbar'
import { isMobile, isIE } from './helper.js'
//-----------------------------------------------------------------------------------------------------------------


// Fonction déterminant le type de scroll en desktop ou mobile
export function getScrollType() {
  if (!isMobile() && !isIE()) window.mainScrollbar = Scrollbar.init(document.querySelector('[data-mainScrollbar]'), { damping: 0.1, syncCallbacks: true, })
  else $('html').addClass('isMobile')
}


// Fonctions retournant l'événement de scroll à donner si le smoothscroll est activé ou pas
export function setScrollEvent(myFunction) {
  if(Scrollbar.has(document.querySelector('[data-mainScrollbar]'))) window.mainScrollbar.addListener(myFunction)
  else $(window).on('scroll', myFunction)
}


// Donne la position de la scrollbar selon son type
export function getScrollTop() {
  if(Scrollbar.has(document.querySelector('[data-mainScrollbar]'))) return window.mainScrollbar.scrollTop
  else return $(window).scrollTop()
}


// Fonction ajoutant tous les éléments fixed à l'écran
export function addFixedElements() {
  window.parallax.update()
  window.scrollfire.update()
  setScrollEvent(posScrollBack)
}


// Fonction positionnant la position du scrollback
export function posScrollBack(){

  var fixed = document.querySelector('#fixedBtns')
  
  if(getScrollTop() > 700) {
    fixed.classList.add('active')
  } else {
    fixed.classList.remove('active')
  }
  
}