//-------------------------\\
//--- Classes des views ---\\
//-------------------------\\

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Dans ce fichier, on retrouve les différentes fonctions utilisées par les views dans transition.js. Ces fonction
// vont servir à appeler les scripts spécifiques ou les plugins utilisés dans les page web. Les fonctions appelées
// sont réparties dans différents fichiers (slicks, overlay), afin de garder le tout le plus structuré possible.
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

// Importation ----------------------------------------------------------------------------------------------------
import $ from 'jquery'
import Scrollbar from 'smooth-scrollbar'
import svg4everybody from 'svg4everybody/dist/svg4everybody.legacy.js'
import { removeHoverOnMobile, noSamePageDestination } from './functions/helper.js'
import { getScrollType, addFixedElements, posScrollBack } from './functions/scroll.js'
import { drawers, getImageOnClick, scrollToBlock, concentrationsFilter, fancyPhotos, filterClick, initSharethis } from './functions/functions.js'
import { slickTestimonials, slickLastProperties, slickProperty } from './functions/slicks.js'
import { overlayMenu, overlayShare } from './functions/overlay.js'
import { fixInput, textareaHeight, magicSelect, formContact, formatPhone, manageConditionnal, gererDropzone} from './functions/forms.js'
import { Parallax } from './classes/Parallax.js'
import { Scrollfire } from './classes/Scrollfire.js'
//-----------------------------------------------------------------------------------------------------------------

// Initialisation sur toutes les pages
export class View {
  static init() {
    svg4everybody()
    removeHoverOnMobile()
    noSamePageDestination()
    getScrollType()
    window.lazyLoad.lazyBackground()
    window.parallax = new Parallax()
    window.scrollfire = new Scrollfire()
    addFixedElements()
    overlayMenu()
    overlayShare()
    textareaHeight()
    initSharethis()
    slickLastProperties()
    posScrollBack()
    scrollToBlock('#scrollBack', 0)
  }

  static leave() {
    $(window).off('scroll')
    setTimeout(()=>{
      window.parallax.destroy()
      Scrollbar.destroyAll()
      destroySlick('.mobilePropertiesSlider')
    }, 900)
    window.scrollfire.destroy()
  }
}


// Classe initialisant les scripts présents dans la page d'accueil
export class Home extends View {
  static initHome() {
    window.activeView = 'home'
    this.init()
    slickTestimonials()
  }

  static leaveHome() {
    this.leave()
  }
}


// Classe initialisant les scripts présents les pages normales
export class Generic extends View {
  static initGeneric() {
    window.activeView = 'generic'
    this.init()
    drawers()
  }

  static leaveGeneric() {
    this.leave()
  }
}

// Classe initialisant les scripts présents la page d'adhésion
export class Adhesion extends View {
  static initAdhesion() {
    window.activeView = 'adhesion'
    this.init()
    fixInput()
    formatPhone('.validationTel input')
    magicSelect()
    manageConditionnal()
    gererDropzone()
  }

  static leaveAdhesion() {
    this.leave()
  }
}

// Classe initialisant les scripts présents la page propriétés à vendre
export class Properties extends View {
  static initProperties() {
    window.activeView = 'properties'
    this.init()
  }

  static leaveProperties() {
    this.leave()
  }
}

// Classe initialisant les scripts présents les pages de détails de propriétés à vendre
export class Property extends View {
  static initProperty() {
    window.activeView = 'properties'
    this.init()
    slickProperty()
  }

  static leaveProperty() {
    this.leave()
  }
}

// Classe initialisant les scripts présents la page contact
export class Contact extends View {
  static initContact() {
    window.activeView = 'contact'
    this.init()
    fixInput()
    formContact()
  }

  static leaveContact() {
    this.leave()
  }
}