//----------------------------\\
//---- Popup et sidepanel ----\\
//----------------------------\\

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Ici on retrouve tout ce qui a attrait au popup et au sidepanel (popup de connexion, menu mobile)
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

// Importation ----------------------------------------------------------------------------------------------------
import $ from 'jquery'
import { Overlay } from './../classes/overlay.js'
import { copyTextToClipboard } from './functions.js'
//-----------------------------------------------------------------------------------------------------------------


// Fonction gérant le menu mobile
export function overlayMenu() {
  new Overlay('overlayMenu', '.mobileMenuToggle', 'showOverlayMenu', '.btnSubLvl1 a, #overlay-menu .closeOverlay, header .logo img')

  $('.btnMenu').on('click', function() {
    $('.subLvl1, .subLvl2').animate({ height: 'hide' }, { duration: 400, specialEasing: { height: 'easeInOutCubic' }})
    $('.btnSubLvl1, .btnSubLvl2').removeClass('active')
    $('.subLvl1, .subLvl2').removeClass('open')
  })

  $('.btnSubLvl1 > a').on('click', function() {
    // Fermeture de tout avant d'en ouvrir un autre
    if (!$(this).parent().hasClass('active')) {
      $('.subLvl1, .subLvl2').animate({ height: 'hide' }, { duration: 400, specialEasing: { height: 'easeInOutCubic' }})
      $('.btnSubLvl1, .btnSubLvl2').removeClass('active')
      $('.subLvl1, .subLvl2').removeClass('open')
    }
    // Gestion ouverture et fermeture
    if (!$(this).parent().find('.subLvl1').hasClass('open')) {
      $(this).parent().find('.subLvl1').animate({ height: 'show' }, { duration: 400, specialEasing: { height: 'easeInOutCubic' }})
      $(this).parent().find('.subLvl1').addClass('open')
      $(this).parent().addClass('active')
    } else {
      $(this).parent().find('.subLvl1').animate({ height: 'hide' }, { duration: 400, specialEasing: { height: 'easeInOutCubic' }})
      $(this).parent().find('.subLvl1').removeClass('open')
      $(this).parent().removeClass('active')
    }
  })

  $('.btnSubLvl2 > a').on('click', function() {
    // Fermeture de tout avant d'en ouvrir un autre
    if (!$(this).parent().hasClass('active')) {
      $('.subLvl2').animate({ height: 'hide' }, { duration: 400, specialEasing: { height: 'easeInOutCubic' }})
      $('.btnSubLvl2').removeClass('active')
      $('.subLvl2').removeClass('open')
    }
    // Gestion ouverture et fermeture
    if (!$(this).parent().find('.subLvl2').hasClass('open')) {
      $(this).parent().find('.subLvl2').animate({ height: 'show' }, { duration: 400, specialEasing: { height: 'easeInOutCubic' }})
      $(this).parent().find('.subLvl2').addClass('open')
      $(this).parent().addClass('active')
    } else {
      $(this).parent().find('.subLvl2').animate({ height: 'hide' }, { duration: 400, specialEasing: { height: 'easeInOutCubic' }})
      $(this).parent().find('.subLvl2').removeClass('open')
      $(this).parent().removeClass('active')
    }
  })

  $('#overlay-menu a[href]:not([target="_blank"])').on('click', function() {
    $('.btnMenu').trigger('click')
  })
}

// Fonction gérant le popup de partage
export function overlayShare() {
  new Overlay('overlayShare', '.btnShare', 'showOverlayShare', '#overlay-share .closeOverlay, #overlay', 600, true, false)

  /*if (typeof __sharethis__ != 'undefined') {
    if (__sharethis__.customRefresh === true) {
      __sharethis__.init(__sharethis__.config)
    } else {
      __sharethis__.customRefresh = true
    }
  }*/

  $('.btnShare').click( function() {
    var attr = $(this).attr('data-url')
    if (typeof attr !== typeof undefined && attr !== false) {
      $('#overlay-share ul > li > a').attr('data-url', document.domain+attr)
    } else {
      $('#overlay-share ul > li > a').attr('data-url', window.location.href)
    }
    $('#emailShareBtn').attr('href','mailto:?Subject=Collège de l’Assomption&body=' + encodeURIComponent(document.location))
  })

  $('#copyShareBtn').click( function() {
    copyTextToClipboard($(this).data('url'))
    alert('Un url a été copié sur votre presse-papiers.')
    return false
  })
}





