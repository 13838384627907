//-----------------------\\
//--- Carousel Slicks ---\\
//-----------------------\\
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Toutes les fonctions utilisant les carousel de slick se retrouve ici. Ces fonction ont besoin du
// node-module slick carousel pour fonctionner (et le css qui vient avec).
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

// Importation ----------------------------------------------------------------------------------------------------
import $ from 'jquery'
//-----------------------------------------------------------------------------------------------------------------

// Création du slick de la bannière
export function slickTestimonials() {
  
  // Initialisation du slick
  $('.testimonialsSlider').slick({
    arrows: true,
    nextArrow: '.nextTestimonial',
    dots: false,
    infinite: true,
    autoplay: false,
    draggable: true,
    pauseOnHover: false,
    fade: true,
    autoplaySpeed: 4000,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
  })
}


export function slickProperty() {
  // Initialisation du slick
  $('.propertySlides').slick({
    arrows: true,
    dots: false,
    infinite: true,
    autoplay: false,
    draggable: true,
    pauseOnHover: false,
    autoplaySpeed: 4000,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
  })
}

export function slickLastProperties() { //Seulement utilisé sur mobile dans les sections denrnières propriétés
  // Initialisation du slick
  $('.mobilePropertiesSlider').slick({
    arrows: false,
    dots: true,
    infinite: true,
    autoplay: true,
    draggable: true,
    pauseOnHover: false,
    autoplaySpeed: 4000,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
  })
  equalHeightSlides()
  $( window ).resize(function() {
    equalHeightSlides()
  })

  function equalHeightSlides(){ //Égaliser les hauteur des slides du slider
    if($( window ).width() <= 600 ){
      var maxH = 0
      $( '.mobilePropertiesSlider .hoverOnBlock' ).each(function() {
        $(this).css('height','auto')
        var h = $(this).outerHeight()
        if( h > maxH ) maxH = h
      })
      $( '.mobilePropertiesSlider .hoverOnBlock' ).each(function() {
        $(this).css('height',maxH + 'px')
      })
    }
  }

}

// Fonction permettant de détruire un carousel Slick
export function destroySlick(carousel, delai) {
  // Paramètres optionnels
  delai = (typeof delai === 'undefined') ? 1000 : delai

  if($(carousel.length)){
    // Un délai pour que le slick ne se détruise pas avant que la transition de page ne soit apparu
    setTimeout(function() {
      $(carousel).slick('unslick')
    }, delai)
  }
}
