//-------------------\\
//---- Classe UI ----\\
//-------------------\\

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Cette classe contient les éléments UI accessibles par l’exterieur.
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export default new class UI {
  // cette fonction ajoute un attribut dynamiquement à l’objet
  add(nameAttribute, dataBinded) { this[nameAttribute] = dataBinded }
  getAttributes() { return Object.keys(this) }
}
