//--------------------------------\\
//--- Validation de formulaire ---\\
//--------------------------------\\

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Dans ce fichier, on retrouve toutes les validations de formulaire, les messages d'erreur et le formatage.
// Pour que tout fonctionne, il faut les node-modules formatter et jquery-validation déjà.
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

// Importation ----------------------------------------------------------------------------------------------------
import $ from 'jquery'
import Scrollbar from 'smooth-scrollbar'
//-----------------------------------------------------------------------------------------------------------------


// LES FONCTIONS
// Fonction qui gère le label des input
export function fixInput() {
  $('input, textarea').each(function() {
    ($(this).val() != '' ? $(this).addClass('notEmpty') : $(this).removeClass('notEmpty'))
  })

  // Si le focus est sur le champ, on enlève la classe vide pour que le label se déplace
  $('input, textarea').focusin(function() {
    $(this).removeClass('notEmpty')
    $(this).parent().removeClass('notEmpty')
    $(this).parent().addClass('hasFocus')
  })

  // Si le champ est vide, on replace le label à sa position d'origine au focusout
  $('input, textarea').focusout(function() {
    if($(this).val() != ''){
      $(this).addClass('notEmpty')
      $(this).parent().addClass('notEmpty')
    }else{
      $(this).removeClass('notEmpty')
      $(this).parent().removeClass('notEmpty')
    }
    $(this).parent().removeClass('hasFocus')
    
  })
}


// Fonction permettant de modifier la hauteur d'un textarea par rapport à son contenu
export function textareaHeight() {
  $('body').on('change keydown keyup keydown paste cut', 'textarea', function () {
    $(this).height(0).height(this.scrollHeight)
  }).find('textarea').change()
}

// Transforme les selects
export function magicSelect() {
  $('.chosen-select').chosen({ disable_search: true })
  $('.chosen-select').each(function() {
    checkPlaceholder($(this))
  })
  $('.chosen-select').change(function() {
    checkPlaceholder($(this))
  })

  function checkPlaceholder(select){
    if(select.find('option:selected').val()==''){
      select.next().addClass('hisPlaceholder')
    }else{
      select.next().removeClass('hisPlaceholder')
    }
  }
}

// Permet de changer le label des input files
export function changeLabelUpload() {
  $('input[type=file]').change(function() {
    $(this).next('label').find('span').text($(this).val().split(/(\\|\/)/g).pop())
  })
}



// LES MESSAGES
// Fonction contenant tous les messages
export function getMessages() {
  return {
    required: 'Ce champ est obligatoire.',
    select: 'Veuillez sélectionner une option.',
    email: 'Veuillez fournir une adresse électronique valide.',
    phone: 'Veuillez fournir un numéro de téléphone valide.',
    postale: 'Veuillez fournir un code postal valide.',
    date: 'Veuillez fournir une date valide.',
    accept: 'Le fichier doit être un document pdf, docx, doc ou rtf.',
    file: 'Veuillez lier le fichier adéquat pour ce champ.',
    equalTo: 'Ce champ doit être identique au champ associé',
    bulletin: 'Veuillez joindre le bulletin le plus récent de votre enfant.'
  }
}



// LES RÈGLES __________
// Règle du email
export function validationEmail() {
  $.validator.addMethod('courriel', function(value, element) {
    return this.optional(element) || /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/.test(value)
  })
}

// Règle du poids maximal d'un fichier
function validationFilesize() {
  $.validator.addMethod('filesize', function(value, element, param) {
    return this.optional(element) || (element.files[0].size <= param)
  }, 'La taille maximale du fichier doit être 1MB')
}



// LES FORMATS __________
// Format du téléphone
export function formatPhone(element) {
  $(element).mask('(000) 000-0000')
}

// Format du code postal
export function formatCodePostal(element) {
  $(element).mask('ABA BAB', {'translation': {
    A: {pattern: /[A-Za-z]/},
    B: {pattern: /[0-9]/}
  }})
}

// Format de la date
export function formatDate(element) {
  $(element).mask('0000-00-00')
}


export function formatYear(element) {
  $(element).mask('0000')
}

export function formatMonth(element) {
  $(element).mask('00')
}

export function formatCreditCard(element) {
  $(element).mask('0000 0000 0000 0000')
}

// LES VALIDATIONS __________
// Fonction exécutant la validation de chaque formulaire
export function formValidation(form, submit, rules, messages, handler) {
  $.validator.setDefaults({
    ignore: []
  })

  $(form).validate({
    debug: false,
    rules: rules,
    messages: messages,
    errorElement: 'div',
    errorPlacement: function(error, element) {
      var placement = $(element).data('error')
      if (element.attr('type') == 'radio') {
        error.insertBefore(element.closest('.radioField').find('.errorblock'))
      } else if (element.attr('type') == 'checkbox') {
        error.insertBefore(element.closest('.checkboxField').find('.errorblock'))
      } else if (placement) {
        $(placement).append(error)
      } else {
        error.insertBefore(element)
      }
    },
    submitHandler: function() {
      if (typeof handler != 'undefined') {
        handler()
        return false
      }
      return true
    }
  })

  $(submit).click(function() {
    // Permet de défiler à la première erreur
    if(!$(form).valid()) {
      $('.error').each(function() {
        if($(this).css('display') != 'none') {
          if(Scrollbar.has(document.querySelector('[data-mainScrollbar]'))) {
            window.mainScrollbar.scrollTo(0, ($(this).offset().top + window.mainScrollbar.offset.y) - ($(this).parent().height() + 100) , 600)
          } else {
            $('html, body').animate({ scrollTop: $(this).offset().top - ($(this).parent().height() + 100 ) }, 600, 'easeInOutExpo')
          }
          return false
        }
      })
    }
    else{
      if($('.formBlock').length){
        if(Scrollbar.has(document.querySelector('[data-mainScrollbar]'))) {
          window.mainScrollbar.scrollTo(0, ($('.formBlock').offset().top + window.mainScrollbar.offset.y) , 600)
        } else {
          $('html, body').animate({ scrollTop: $('.formBlock').offset().top }, 600, 'easeInOutExpo')
        }
      }
    }

    $(form).submit()

  })
}


// Validation du formulaire de contact
export function formContact() {
  validationEmail()
  formatPhone('#contactTelephone')
  var m = getMessages()
  var rules = {
    nom: { required: true },
    prenom: { required: true },
    telephone: { required: true, minlength: 14 },
    courriel: { required: true, courriel: true },
    message: { required: true },
  }
  var messages = {
    nom: { required: m.required },
    prenom: { required: m.required },
    telephone: { required: m.required, minlength: m.phone },
    courriel: { required: m.required, courriel: m.email },
    message: { required: m.required },
  }

  formValidation('#formContact', '#submitContact', rules, messages)
}


// Validation du formulaire de contact
export function formSearch(formID,formSubmit) {
  var m = getMessages()
  var rules = {
    q: { required: true },
  }
  var messages = {
    q: { required: m.required },
  }

  formValidation(formID, formSubmit, rules, messages)
}


// Envoi des formulaire du plugin Renatio Form Builder
export function formSent() {
	$('body').removeClass('oc-loading')
  $('.stripe-loading-indicator').addClass('loaded')
  $('.formContainer .form-alert.alert-success').show()
  $('.formContainer form').remove()
  scrollNow('.formContainer .confirmation', 600, -150)
}

function scrollNow(destination, duration, offset) {
  // Paramètres optionnels
  duration = (typeof duration === 'undefined') ? 600 : duration
  offset   = (typeof offset   === 'undefined') ? 0   : offset

  // Défilement au clique
  if(Scrollbar.has(document.querySelector('[data-mainScrollbar]'))) {
    window.mainScrollbar.scrollTo(0, ( destination != '' ? ($(destination).offset().top + window.mainScrollbar.offset.y) + offset : 0), 600)
  } else {
    $('html, body').animate({ scrollTop: ( destination != '' ? $(destination).offset().top + offset : 0 ) }, duration, 'easeInOutExpo')
  }
}

// Validation du formulaire de contact
export function manageConditionnal() {
  $( ".column.conditionnal" ).each(function() {
    $(this).prev().find('input,select').change(function() {
      if(($(this).val() == 'Oui')||($(this).val() == 'Autre')){
        $(this).closest('.column').next().show();
      }else{
        $(this).closest('.column').next().hide();
      }
    })
  })

  $( ".column.statut select" ).change(function() {
    console.log($(this).val())
    if(($(this).val()=="Conjoint")||($(this).val()=="Marié")){
      var fields  = $( ".column.conditionnal_conjoint:not('.conditionnal_conjoint.conditionnal')" )
      fields.show()
    }else{
      var fields  = $( ".column.conditionnal_conjoint" )
      fields.find('input,textarea').val('').removeClass('notEmpty')
      fields.hide()
    }
  })
}

export function gererDropzone(){
  if ($('.control-multi-file-uploader').length) {

      $(document).ready(function(){
          $('[data-control=multi-file-uploader]').multiFileUploader()
      })

  }
}