//------------------------\\
//---- Classe Overlay ----\\
//------------------------\\

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Classe permettant d'ajouter un lazy load sur les images
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export class LazyLoad {

  // Chargement progressif des images de background
  lazyBackground() {
    var lazyBackgrounds = [].slice.call(document.querySelectorAll('[data-background]'))

    // Si le navigateur supporte l'API IntersectionObserver
    if ('IntersectionObserver' in window && 'IntersectionObserverEntry' in window && 'intersectionRatio' in window.IntersectionObserverEntry.prototype) {
      if('IntersectionObserver' in window) {
        let lazyBackgroundObserver = new IntersectionObserver(function(entries) {
          entries.forEach(function(e) {
            if(e.isIntersecting) {
              var loading = new Image()
              loading.src = e.target.dataset.background
              loading.onload = function () {
                e.target.style.backgroundImage = 'url(\'' + e.target.dataset.background + '\')'
                e.target.setAttribute('data-loaded', '')
                e.target.classList.add('loaded')
              }
              lazyBackgroundObserver.unobserve(e.target)
            }
          })
        }, {
          root: document.querySelector('[data-mainScrollbar]'),
          rootMargin: '1000px 30px 1000px 30px'
        })

        lazyBackgrounds.forEach(function(lazyBackground) {
          lazyBackgroundObserver.observe(lazyBackground)
        })
      }
    } else {
      lazyBackgrounds.forEach(function(e) {
        e.style.backgroundImage = 'url(\'' + e.dataset.background + '\')'
        e.classList.add('loaded')
      })
    }
  }

}
