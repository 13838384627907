//-----------------\\
//--- Les views ---\\
//-----------------\\

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Ce fichier contient une seule fonction qui celle-ci contient toutes les views du site. Une views équivaux à
// une page web (homeView = la page d'accueil). On appelle seulement les classes dans global dans les vues, on
// n'appelle pas d'autres fonctions ou déclarer quoi que ce soit dans les vues afin de garder le tout clair.
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

// Importation ----------------------------------------------------------------------------------------------------
import Barba from 'barba.js'

import {
  View, Home, Generic, Adhesion, Properties, Property, Contact
} from './global.js'
//-----------------------------------------------------------------------------------------------------------------


export function setView() {

  // Page standart __________
  Barba.BaseView.extend({
    namespace: 'sdtView',
    onEnter:          function() {              },
    onEnterCompleted: function() { View.init()  },
    onLeave:          function() { View.leave() },
    onLeaveCompleted: function() {              }
  }).init()


  // Page d'accueil __________
  Barba.BaseView.extend({
    namespace: 'homeView',
    onEnter:          function() {},
    onEnterCompleted: function() { Home.initHome()  },
    onLeave:          function() { Home.leaveHome() },
    onLeaveCompleted: function() {}
  }).init()

  // Page générique _________
  Barba.BaseView.extend({
    namespace: 'genericView',
    onEnter:          function() {},
    onEnterCompleted: function() { Generic.initGeneric()  },
    onLeave:          function() { Generic.leaveGeneric() },
    onLeaveCompleted: function() {}
  }).init()

  // Page adhésion _________
  Barba.BaseView.extend({
    namespace: 'adhesionView',
    onEnter:          function() {},
    onEnterCompleted: function() { Adhesion.initAdhesion()  },
    onLeave:          function() { Adhesion.leaveAdhesion() },
    onLeaveCompleted: function() {}
  }).init()

  // Page proriétés à vendre
  Barba.BaseView.extend({
    namespace: 'propertiesView',
    onEnter:          function() {},
    onEnterCompleted: function() { Properties.initProperties()  },
    onLeave:          function() { Properties.leaveProperties() },
    onLeaveCompleted: function() {}
  }).init()

  // Page de détail de propriétés
  Barba.BaseView.extend({
    namespace: 'propertyView',
    onEnter:          function() {},
    onEnterCompleted: function() { Property.initProperty()  },
    onLeave:          function() { Property.leaveProperty() },
    onLeaveCompleted: function() {}
  }).init()

  // Page nous joindre _________
  Barba.BaseView.extend({
    namespace: 'contactView',
    onEnter:          function() {},
    onEnterCompleted: function() { Contact.initContact()  },
    onLeave:          function() { Contact.leaveContact() },
    onLeaveCompleted: function() {}
  }).init()
}
