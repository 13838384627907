//-------------------------------\\
//--- Importation des scripts ---\\
//-------------------------------\\

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Main.js importe tous les fichiers qu'on a de besoin, ça commence par les node-modules, puis les fichiers
// propres au projet.
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////


// Importation des nodes modules __________
import $ from 'jquery'
window.jQuery = $
import 'jquery.easing'
import 'jquery-validation'
import 'jquery-validation/dist/additional-methods.js'
import 'slick-carousel'
import '@fancyapps/fancybox'
import 'slick-carousel/slick/slick-theme.scss'
import 'slick-carousel/slick/slick.scss'
import 'jquery-mask-plugin'
import 'chosen-js'
import 'bootstrap-datepicker'


// Importation des scripts __________
import { LazyLoad } from './classes/lazyLoad.js'
import './transition.js'
import './global.js'


// Importation pour les scripts un peu plus bas __________
import { initPageTransition } from './transition.js'
import { propertiesLoaded, shareUrl } from './functions/functions.js'
import { formSent } from './functions/forms.js'

window.propertiesLoaded = propertiesLoaded
window.shareUrl = shareUrl
window.formSent = formSent

// Démarrage des scripts __________
$(document).ready(function() {
  window.lazyLoad = new LazyLoad()

  initPageTransition()
  setTimeout(function() {
    $('html').removeClass('chargement')
  }, 600)
  $(document).scrollTop(0)

  $('body').keydown( function(e) {
    var keycode = (e.keyCode ? e.keyCode : e.which)
    if ((e.ctrlKey  && keycode == 13) || (e.metaKey  && keycode == 13)) {
      location = '/administration'
      return false
    }
  })
})
