//------------------------\\
//---- Classe Overlay ----\\
//------------------------\\

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Classe permettant d'ajouter une classe au HTML pouvant ouvrir un overlay
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

// Importation ----------------------------------------------------------------------------------------------------
import $ from 'jquery'
import UI from './UI.js'
import Scrollbar from 'smooth-scrollbar'
import { addFixedElements } from './../functions/scroll.js'
import { isMobile } from '../functions/helper.js'
//-----------------------------------------------------------------------------------------------------------------


export class Overlay {
  constructor(nameOverlay, targetedElement, targetedClass, closingElements = '', delay = 600, noscroll = true, backToTop = true) {
    this.element         = targetedElement
    this.class           = targetedClass
    this.closingElements = closingElements
    this.delay           = delay
    this.noscroll        = noscroll
    this.backToTop       = backToTop
    this.width           = $(window).width()
    
    // Toggle de ouvrir et fermer l'overlay
    $(this.element).on('click', (e) => {
      if(!$('html').hasClass(this.class))
        this.open()
      else
        this.close(e)
    })

    // Fermer l'overlay au clique des éléments de fermeture
    $(this.closingElements).on('click', (e) => {
      this.close(e)
    })

    // Ferme l'overlay au resize de la fenêtre
    $(window).on('resize', () => {
      var newWidth = $(window).width()
      if(newWidth == this.width) { return } else {
        this.width = $(window).width()
        this.close()
      }
    })

    // Ajoute l'objet à UI pour qu'elle soit disponible de l'extérieur
    UI.add( nameOverlay , this )
  }


  // Permet d'ouvrir un overlay
  open() {
    if(this.noscroll) this.addNoScroll()
    $('html').addClass(this.class)
    $('html').addClass('overlayOpening')
    setTimeout(() => { $('html').removeClass('overlayOpening') }, this.delay)
  }


  // Permet de fermer un overlay
  close(closingElement = false) {
    if($('html').hasClass(this.class)) {
      $('html').addClass('overlayClosing')
      setTimeout(() => {
        $('html').removeClass(this.class).removeClass('overlayClosing')
        if(closingElement != false) {
          if(!$(closingElement.currentTarget).hasClass('keepNoScroll')) this.removeNoScroll()
        } else {
          this.removeNoScroll()
        }
      }, this.delay)
    }
  }


  // Ajoute le noscroll lorsqu'un overlay est ouvert empêchant le scroll dans la page
  addNoScroll() {
    if(isMobile()) {
      if(this.backToTop) $(document).scrollTop(0)
      if($(document).height() > $(window).height()) {
        var scrollTop = ($('html').scrollTop()) ? $('html').scrollTop() : $('body').scrollTop()
        $('html').addClass('noscroll').css('top',-scrollTop)
      }
    } else {
      if(Scrollbar.has(document.querySelector('[data-mainScrollbar]'))) {
        window.mainScrollbar.destroy()
      }
    }
  }


  // Retire le noscroll
  removeNoScroll() {
    if(isMobile()) {
      if(this.backToTop) {
        $('html').removeClass('noscroll')
        $('html,body').scrollTop(0)
      } else {
        var scrollPosition = parseInt($('html').css('top'))
        $('html').removeClass('noscroll')
        $('html,body').scrollTop(-scrollPosition)
      }
    } else {
      if(!Scrollbar.has(document.querySelector('[data-mainScrollbar]'))) {
        window.mainScrollbar = Scrollbar.init(document.querySelector('[data-mainScrollbar]'), { damping: 0.1 })
        addFixedElements()
      }
    }
  }
}
